import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import RefProgImg from "../images/theme-photos-CGpifH-1.png"
import { Link } from "gatsby"

import payrollIcon1 from "../images/payroll/payroll-icon1.svg"
import payrollIcon2 from "../images/payroll/payroll-icon2.svg"
import payrollIcon3 from "../images/payroll/payroll-icon3.svg"

const ReferralProgram = () => {
  return (
    <Layout>
      <SEO title="Referral Program" />
      <div className="r-program-page">
        <Container className="pb-100" style={{ paddingTop: "200px" }}>
          <h1 className="bottom-underline text-center text-blue">
            REFERRAL PROGRAM
          </h1>
        </Container>

        <Container>
          <Row>
            <Col lg="7 d-flex justify-content-center align-items-center">
              <div>
                {" "}
                <h1
                  className="ps-main-headline"
                  style={{
                    color: "#152c4b",
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  Make some money by leveraging your own network
                </h1>
                <h3 style={{ fontSize: "25px", fontWeight: "100" }}>
                  Over 60% of all candidates find their next job through
                  networking.
                </h3>
              </div>
            </Col>
            <Col lg="5">
              <img className="w-100" src={RefProgImg} alt="Payroll Services" />
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{
            backgroundColor: "#f9f9f9",
            padding: "100px 0 100px",
            marginTop: "100px",
          }}
        >
          <Container>
            <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
              <div className="">
                <h2 className="mb-4">
                  Why not make some easy money from that?
                </h2>
                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  We all have a wide network of professionals in industries such
                  as IT, accounting, and others.
                </p>
                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  Know of any who are currently on the market for something new?
                  Know of any who are more of a passive candidate that may jump
                  on a good opportunity if they see one?
                </p>
              </div>
              <div className="">
                <h2 className="my-4">
                  Let us introduce you to our Candidate Referral Program via the
                  PeerSource PeerNetwork.{" "}
                </h2>
                <p
                  className="mb-4"
                  style={{ fontSize: "23px", lineHeight: "1.8" }}
                >
                  This program allows you to refer to PeerSource the names and
                  contact info of professionals within your own personal
                  network. If you help us fill one of our many openings with
                  someone you’ve referred to us,{" "}
                  <strong>we’ll write you a $500 check!</strong>
                </p>
                <p
                  className="mb-4"
                  style={{ fontSize: "23px", lineHeight: "1.8" }}
                >
                  To qualify, send us an email with your referral’s name, phone
                  number, and email address. As long as they are not an active
                  candidate we are currently speaking to and the person starts a
                  new position within 1 year of the referral (and stays through
                  our guarantee period), we’ll write you that check.
                </p>
                <p
                  className="mb-4"
                  style={{ fontSize: "23px", lineHeight: "1.8" }}
                >
                  <strong>
                    You have nothing to lose and only extra $$ to gain...
                    connect with us to learn more!{" "}
                  </strong>
                </p>
              </div>
            </div>
          </Container>
        </Container>

        <Container
          fluid
          style={{ backgroundColor: "#0d2e4f", padding: "100px 0" }}
        >
          <Container>
            {" "}
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg="4">
                  <div className="ps-vp-box2 text-center">
                    <img src={payrollIcon1} alt="" />
                    <h5 className="m-4">Looking for IT Talent?</h5>
                    <p>
                      Our PeerNetwork model and experienced recruiters allows us
                      to offer a wide range of technical and non-technical
                      talent for our clients.
                    </p>
                    <Link to="/recruiting-services">Learn More</Link>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="ps-vp-box2 text-center">
                    <img src={payrollIcon2} alt="" />
                    <h5 className="m-4">Search for Jobs</h5>
                    <p>Check out our current opportunities!</p>
                    <Link to="/jobs">Learn More</Link>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="ps-vp-box2 text-center">
                    <img src={payrollIcon3} alt="" />
                    <h5 className="m-4">PeerNetwork</h5>
                    <p>
                      Find out more about our unique recruiting model and how
                      you can earn money on the side while improving your
                      professional network.
                    </p>
                    {/* <Link to="/peernetwork">Learn More</Link> */}
                    <Link to="/contact-us">Learn More</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default ReferralProgram
